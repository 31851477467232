import adrenalin from "./images/adrenalin.png"
import squashlife from "./images/squashlife.png"
import lazurny from "./images/lazurny.png"
export const supplierTestimonials = [
  {
    logo: adrenalin,
    text:
      "Мы очень довольны работой с командой AllSports! Это сотрудничество позволило привлечь в зал большое количество\n" +
      "новых клиентов и увеличить полезную нагрузку на наш зал.",
    position: "Директор",
    name: 'Тренажерный зал "Адреналин"',
  },
  {
    logo: squashlife,
    text:
      "За время сотрудничества с AllSports результаты превзошли все наши ожидания. Количество посещений наших кортов\n" +
      "увеличилось в разы благодаря притоку корпоративных клиентов! ОЛЛСПОРТС - это команда профессионалов, которая\n" +
      "поможет вашему бизнесу расти.\n",
    position: "Директор",
    name: "Squash Life",
  },
  {
    logo: lazurny,
    text: "Благодаря работе с AllSports поток клиентов в наш центр существенно вырос, равно как и увеличилось число постоянных клиентов. Также хотелось бы отменить, что сотрудничество строиться максимально просто и прозрачно.",
    position: "Директор",
    name: 'Спорткомбинат "Лазурный"',
  },
]
