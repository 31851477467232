import React, { useState, useRef } from "react"
import { useTranslation } from "react-i18next"

const ReadMore = ({ children, maxVisiableSimbols }) => {
  const { t } = useTranslation()

  const paragraphRef = useRef(null)

  const text = children
  let endPoint = maxVisiableSimbols

  while (text[endPoint] === " ") {
    endPoint--
  }

  const visiableText = text.slice(0, endPoint) + "..."

  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return text.length < maxVisiableSimbols ? (
    <p className="text">{text}</p>
  ) : (
    <p className="text">
      <span>{isReadMore ? visiableText : text}</span>

      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? t("testimonials.more") : t("testimonials.less")}
      </span>
    </p>
  )
}

export default ReadMore
