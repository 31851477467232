import React, { useEffect, useState } from "react"
import "./Testimonials.scss"
import ReadMore from "../../common/ReadMore/ReadMore"

function shuffle(array) {
  array.sort(() => Math.random() - 0.5)
}

function loadTestimonials(baseTestimonials, names) {
  let result = baseTestimonials
  if (names) {
    result = result.filter((testimonial) => {
      return names.indexOf(testimonial.name) !== -1
    })
  }
  return result
}

function Testimonials({ title, baseTestimonials, names, doShuffle, lang }) {
  const [testimonials, setTestimonials] = useState(
    loadTestimonials(baseTestimonials, names)
  )
  const [shuffled, setShuffled] = useState(false)
  useEffect(() => {
    if (!shuffled && doShuffle) {
      shuffle(testimonials)
      setShuffled(true)
      setTestimonials((testimonials) => testimonials)
    }
  }, [doShuffle, shuffled, testimonials])
  const [index, setIndex] = useState(0)
  function increase() {
    setIndex((index + 1) % testimonials.length)
  }
  function decrease() {
    setIndex(index === 0 ? testimonials.length - 1 : index - 1)
  }
  return (
    <section className="testimonials">
      <div className="title">{title}</div>

      {testimonials.length > 3 && (
        <div className="slider">
          <button
            className="left"
            onClick={decrease}
            aria-label="decrease"
          ></button>
          <span>
            {index + 1}/{testimonials.length}
          </span>
          <button
            className="right"
            onClick={increase}
            aria-label="increase"
          ></button>
        </div>
      )}

      <div>
        {testimonials.map((count, itemIndex) => {
          let newIndex = index + itemIndex
          if (!testimonials[newIndex]) {
            newIndex = newIndex - testimonials.length
          }
          const { logo, text, position, name } = testimonials[newIndex]
          return (
            <div key={count}>
              <div className="image-wrapper">
                <img src={logo} alt={"лого " + name} />
              </div>
              <span>{position}</span>
              <span>{name}</span>
              <ReadMore maxVisiableSimbols={150}>{text}</ReadMore>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Testimonials
