import React from "react"
import "./Partners.scss"

import { useTranslation } from "react-i18next";
import logo320 from "./images/06.06_Partners_logo_320.png";
import logo768 from "./images/06.06_Partners_logo_768.png";
import logo1024 from "./images/06.06_Partners_logo_1024.png";
import logo1280 from "./images/06.06_Partners_logo_1280.png";

function Clients() {
  const { t } = useTranslation()

  return (
    <section className="clients">
      <div className="title">{t("partners.title")}</div>

      <div>
        <img
          srcSet={
            logo320 +
            " 320w," +
            logo768 +
            " 768w," +
            logo1024 +
            " 1024w," +
            logo1280 +
            " 1280w"
          }
          sizes="(min-width: 320px) 320w,
            (min-width: 768px) 768w,
            (min-width: 1024px) 1024w,
            (min-width: 1280px) 1280w,
            768w"
          src={logo768}
          alt="Suppliers logos"
        />
      </div>
    </section>
  )
}

export default Clients
